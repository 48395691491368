export const environment = {
  production: false,
  gaiminApi: 'https://api.khrystyna.colosseum.gaimingladiators.gg/api',
  authModuleUrl: 'https://khrystyna.auth.gaimin.io',
  authModuleApi: 'https://api.khrystyna.auth.gaimin.io/api',
  shopifyApi: 'https://pay.gaimin.gg',
  walletConnectProjectId: 'f3c4c117e79af11c9a5063ba89abb8f3',
  gmrxTokenContractAddress: '0x97B8e73a6d4Ff3Bb87a8b73f4FF921f6d446F097',
  dgaSmartContractAddress: '0xadb3a8d6c03a9979f4e86b81d2fc448bc59685d1',
  colosseumSubscriptionContract: '0x6bCd4e400B0490D26F54537446256f0243d9c929',
  gaiminGladiatorsBlogLink: 'https://www.gaimingladiators.gg/blog-category/the-colosseum',
  discordClientId: '1213129038143688744',
  discordClientSecret: 'Vp1McRRNeAXzbdCThlfSkY5Sxl2vxmqQ',
  discordRedirectUri: 'https://colosseum.khrystyna.gaimin.gg/discord/callback',
  bscSocketlink: 'wss://bsc-testnet.nodereal.io/ws/v1/2ef38ec6ad634e61982608224281d289',
  cloudflareTurnstileSiteKey: '0x4AAAAAAAc7oIofIG7YjF3V',
  meldLink: 'https://meldcrypto.com'
};
