<div class="modal">
  <div class="header">
    <h2>Action Required: Complete Your GAIMIN Account Migration by {{ date }}</h2>
    <span class="icon-Close close-icon" title="Close" (click)="requestClose()"></span>
  </div>

  <div class="modal-content">
    <!-- <h3>PLEASE READ - VERY IMPORTANT ACCOUNT UPGRADE</h3> -->
    <p>
      You are seeing this notice because we identified your email in our old GAIMIN database, and noticed your account
      still needs to be migrated to our new Single Sign-On (SSO) system. You missed the active migration notice but can
      still complete your migration now.
    </p>

    <h3>Why You Need to Act:</h3>
    <ul>
      <li>
        <p><b>Access to your</b> old <b>GAIMIN account</b> will be <b>restricted if you cancel this migration process</b>.</p>
      </li>
      <li>
        <p>After this restriction, you will have to sign in to your wallet via <a href="http://wallet.venly.io">wallet.venly.io</a>  to retrieve your assets.</p>
      </li>
      <li>
        <p>If you have not set up a wallet on your old GAIMIN account, you may risk potential assets loss! </p>
      </li>
    </ul>
    <p><b>Note:</b> Cancelling the migration may result in potential assets loss!</p>


    <h3>What You Need to Do:</h3>
    <ol>
      <li>
        <p>Click <b>“Complete Migration Now”</b> below.</p>
      </li>
      <li>
        <p>
          ‘Re-signup’ on GAIMIN using the same old email, to ensure your old account details and data are linked to the
          new one.
        </p>
      </li>
      <li>
        <p>
          Set up your wallet’s recovery password — store it somewhere securely, as GAIMIN cannot recover this for you if
          lost.
        </p>
      </li>
    </ol>
    <p>
      This migration enhances security and unlocks streamlined access to all existing GAIMIN products and services from
      one account.
    </p>
    <p>
      For more detailed walkthrough on the SSO migration, kindly refer to our
      <a
        href="https://www.gaimin.io/blog/the-gaimin-single-sign-on-sso-upgrade-all-you-need-to-know"
        >FAQ page</a
      >.
    </p>
    <p>
      If you have any questions, kindly reach out to us on <a href="https://discord.com/invite/gaiminarena">Discord</a>.
    </p>
    <!-- <p>
      For any questions, contact our support team at:
      <a href="mailto:technical_support@gaimin.io">technical_support&#64;gaimin.io</a>
    </p> -->

    <p>
      <b>Don’t delay — secure your account now!</b>
    </p>
    <app-button (click)="submit()" [buttonData]="confirmButton"></app-button>
  </div>
</div>
