

<div class="modal">
  <div class="header">
    <h2>Are you sure you want to cancel the migration process?</h2>
    <span class="icon-Close close-icon" title="Close" (click)="back()"></span>
  </div>

  <div class="modal-content">
    <p>
      Once you close this window, you have decided to cancel the migration and will need to retrieve your assets from your
      previously connected <a href="http://wallet.venly.io">Venly wallet</a>.
    </p>
    <p>
      If you had not connected Venly wallet previously on GAIMIN, cancelling this migration may result in potential assets
      loss!
    </p>
    <div class="modal-content__buttons-list">
      <app-button class="dialog__button" [buttonData]="{ name: 'No', type: BUTTON_TYPE.GREY }" (click)="back()"></app-button>
      <app-button class="dialog__button" [buttonData]="{ name: 'Yes' }" (click)="confirm()"></app-button>
    </div>
  </div>
</div>
